.auth-ethos {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;

	h1 {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 0;
	}

	label {
		display: block;
	}

  .row {
    height: 100%;
  }

  .col-md-6.first {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
		padding: 0;

		img {
			width: 250px;
		}
  }

  .col-md-6.second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #35afdb;
		padding: 0;

		.copy {
			h1 {
				text-align: center;
			}

			color: #fff;
			
			padding: 30px 50px;
			background: #2d98bf;

			.btn-primary {
				background: #35afdb!important;
				border-color: #35afdb!important;
			}
		}
  }
}