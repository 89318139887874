.ticket-item {
	background: #f3f3f3;
	border-radius: 8px;
	padding: 20px;

	.ticket-title {
		font-weight: bold;
		font-size: 22px;
		overflow: auto;
	}

	.ticket-description {
		overflow: auto;
		font-size: 16px;
	}

	.btn-block {
		display: block;

		&:hover {
			text-decoration: none;
		}
	}
}