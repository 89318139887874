.database-item {
	text-decoration: none;
	font-size: 16px;
	color: #000;
	background-color: #eee;
	padding: 10px;
	margin-bottom: 10px;
	display: block;
	border-radius: 8px;

	&:hover {
		color: #000;
		background-color: #ddd;
		text-decoration: none;
	}
}